import { ButtonUnstyled } from '@emico-react/buttons'
import { ComponentSlider } from '@emico-react/component-slider'
import { Image } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ComponentProps, useState } from 'react'

import { maxWidth, minWidth } from '@emico/styles'

import { ArrowButton, PrevButton } from '../overrides/ComponentSlider'
import theme from '../theme'
import Modal, { Content as ModalContent } from './Modal'

const StyledModal = styled(Modal)`
  ${ModalContent} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing.sm};

    @media ${minWidth('lg')} {
      padding: ${theme.spacing.lg} ${theme.spacing['2xl']} 0;
    }
  }
`

const centerImageStyling = css`
  height: 100%;

  @media ${minWidth('lg')} {
    height: auto;
  }
`

const StyledComponentSlider = styled(ComponentSlider)`
  ${ArrowButton} {
    top: 50%;
    right: ${theme.spacing.md};
    transform: translateY(-50%);
  }

  ${PrevButton} {
    left: ${theme.spacing.md};
    right: auto;
  }

  @media ${maxWidth('sm')} {
    ${ArrowButton} {
      display: none;
    }
  }

  @media ${maxWidth('md')} {
    ${centerImageStyling};
  }
`

const SliderNavigation = styled.div`
  padding: ${theme.spacing.sm};

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.md} ${theme.spacing['2xl']} ${theme.spacing['2xl']};
  }
`

const StyledComponentSliderMobile = styled(ComponentSlider)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledComponentSliderTablet = styled(ComponentSlider)`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledComponentSliderDesktop = styled(ComponentSlider)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const Figure = styled.figure`
  display: flex;
  align-items: center;
  margin: 0;
`

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive: boolean }>`
  padding: ${theme.spacing.sm};
  border: ${theme.borders.default};
  border-color: ${({ isActive }) => isActive && theme.colors.borderDark};
  border-radius: ${theme.borderRadius.base};
`

const NavigationImage = styled(StyledImage)`
  aspect-ratio: 1 / 1;
  object-fit: cover;
`

interface NavigationComponentSliderProps
  extends Pick<ComponentProps<typeof ComponentSlider>, 'children'> {
  currentSlideIndex: number
}

const NavigationComponentSlider = ({
  children,
  currentSlideIndex,
}: NavigationComponentSliderProps) => (
  <SliderNavigation>
    <StyledComponentSliderMobile
      snapAlign="start"
      slideGap={10}
      slidesToShow={3.5}
      currentSlide={currentSlideIndex}
    >
      {children}
    </StyledComponentSliderMobile>

    <StyledComponentSliderTablet
      snapAlign="start"
      slideGap={10}
      slidesToShow={5}
      currentSlide={currentSlideIndex}
    >
      {children}
    </StyledComponentSliderTablet>

    <StyledComponentSliderDesktop
      snapAlign="start"
      slideGap={10}
      padding={5}
      slidesToShow={7.5}
      currentSlide={currentSlideIndex}
    >
      {children}
    </StyledComponentSliderDesktop>
  </SliderNavigation>
)

interface Props {
  /**
   * State to open the modal
   */
  show: boolean
  /**
   * Function to close the modal (e.g. close())
   */
  close: () => void
  /**
   * List of image urls
   */
  imageUrls: string[]
  /**
   * Number of slides to show
   */
  slidesToShow?: number
  /**
   * Index of the slide that should be active
   */
  currentSlideIndex?: number
  /**
   * Function to set the currentSlideIndex state
   */
  setCurrentSlideIndex: (index: number) => void
}

const ImageSliderModal = ({
  show,
  close,
  imageUrls,
  slidesToShow = 1,
  currentSlideIndex = 0,
  setCurrentSlideIndex,
}: Props) => {
  const [isNavigationSliderUsed, setIsNavigationSliderUsed] =
    useState<boolean>(false)
  const slideCount = imageUrls.length

  return (
    <StyledModal
      title={
        <>
          <Trans>Images</Trans>
        </>
      }
      show={show}
      close={close}
      hasHeaderBackground
      bottom={
        <NavigationComponentSlider currentSlideIndex={currentSlideIndex}>
          {imageUrls.map((url, index) => (
            <StyledButtonUnstyled
              key={`url-${index}`}
              analyticsContext="image.slider.modal"
              analyticsName={`change.slide.${index}`}
              onPress={() => {
                setIsNavigationSliderUsed(true)
                setCurrentSlideIndex(index)
              }}
              isActive={index === currentSlideIndex}
            >
              <NavigationImage
                url={url}
                alt="review-image"
                sizes={theme.imageSizes.square.sizes}
                lazy={false}
              />
            </StyledButtonUnstyled>
          ))}
        </NavigationComponentSlider>
      }
    >
      <StyledComponentSlider
        snapAlign="start"
        slideGap={15}
        slidesToShow={1}
        currentSlide={currentSlideIndex}
        onSlideChange={(index) => {
          if (!isNavigationSliderUsed) {
            setCurrentSlideIndex(index)
          }

          if (isNavigationSliderUsed && index === currentSlideIndex) {
            setIsNavigationSliderUsed(false)
          }
        }}
        showArrows={slideCount > slidesToShow}
      >
        {imageUrls.map((url, index) => (
          <Figure key={`url-${index}`}>
            <StyledImage
              url={url}
              alt="review-image"
              sizes={theme.imageSizes.fullWidth.sizes}
              lazy
            />
          </Figure>
        ))}
      </StyledComponentSlider>
    </StyledModal>
  )
}

export default ImageSliderModal
