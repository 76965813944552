import { Picture } from '@emico-react/image'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import { CraftTableFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import Table from './Table'

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.xl};
  }
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
`

const CraftTableBlockContent = ({ block, ...other }: Props) => {
  const image = block.image?.[0]

  const tableItems = block.tableItemList
    .map((item) =>
      item?.rowLabel && item.richTextSimpleRowValue
        ? {
            label: item?.rowLabel,
            value: item?.richTextSimpleRowValue,
          }
        : undefined,
    )
    .filter(stripMaybes)

  return (
    <div {...other}>
      {image && (
        <Figure>
          <StyledPicture
            style={{}}
            breakpoints={{
              mobile: {
                url: image.mobileUrl ?? image.defaultUrl ?? '',
                width: 722,
                height: undefined,
              },
              tablet: {
                url: image.tabletUrl ?? image.defaultUrl ?? '',
                width: 991,
                height: undefined,
              },
              desktop: {
                url: image.desktopUrl ?? image.defaultUrl ?? '',
                width: 1440,
                height: undefined,
              },
            }}
            alt={image.title ?? ''}
            lazy
          />
        </Figure>
      )}

      {block.tableItemList && (
        <Table tableItems={tableItems} hasRichTextValues hasBoldLabels />
      )}
    </div>
  )
}

interface Props {
  block: CraftTableFragment
  isNestedBlock?: boolean
}

const CraftTableBlock = ({ block, isNestedBlock = false }: Props) =>
  isNestedBlock ? (
    <CraftTableBlockContent block={block} />
  ) : (
    <CraftBlock>
      <Container>
        <CraftTableBlockContent block={block} />
      </Container>
    </CraftBlock>
  )

export default CraftTableBlock
