import { ProductVisibility } from '@emico-hooks/graphql-schema-types'
import { usePreviouslyViewedProducts } from '@emico-hooks/previously-viewed-products'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { pushProductDetailView } from '@emico-utils/datalayer'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useState, useEffect } from 'react'

import { minWidth, maxWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import Alert from '../../components/Alert'
import AttributesModal from '../../components/AttributesModal'
import AttributesTable from '../../components/AttributesTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import Container from '../../components/Container'
import CraftCategoryProductPageBlocks from '../../components/CraftCategoryProductPageBlocks'
import CraftProductPageBlocks from '../../components/CraftProductPageBlocks'
import CrossSellModal from '../../components/CrossSellModal'
import Meta from '../../components/Meta'
import MetaCanonical from '../../components/MetaCanonical'
import MetaNoIndexNoFollow from '../../components/MetaNoIndexNoFollow'
import ProductPageHeader from '../../components/ProductPageHeader'
import ProductPageInfo from '../../components/ProductPageInfo'
import ProductPageMedia from '../../components/ProductPageMedia'
import ProductPageMediaSlider from '../../components/ProductPageMediaSlider'
import ProductPageSection from '../../components/ProductPageSection'
import ProductSliderWithTracking from '../../components/ProductSliderWithTracking'
import Reviews from '../../components/Reviews'
import SchemaProduct from '../../components/SchemaProduct'
import convertProductForTrackingEvent from '../../lib/convertProductForTrackingEvent'
import { AlertProps } from '../../lib/customTypes'
import getMediaItems from '../../lib/getMediaItems'
import getSeoPageTitle from '../../lib/getSeoPageTitle'
import getSplitCraftBlocks from '../../lib/getSplitCraftBlocks'
import { DefaultLayoutStaticData } from '../../lib/useCraftGlobalSets'
import useProductConfigurables from '../../lib/useProductConfigurables'
import theme from '../../theme'
import { ProductPageStaticData } from './useProduct'

function getSlidesToShowTablet(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    default:
      return 2.5
  }
}

function getSlidesToShowDesktop(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    case 3:
      return 3
    default:
      return 3.25
  }
}

const BreadcrumbsWrapperMobileAndTablet = styled.div`
  @media ${minWidth('lg')} {
    display: none;
  }
`

const BreadcrumbsWrapperDesktop = styled.div`
  background-color: ${theme.colors.backgroundLight};

  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${theme.spacing.lg};
  padding: 0 ${theme.containerPadding};

  @media ${minWidth('lg')} {
    margin: 0;
    padding: ${theme.containerPadding} ${theme.containerPadding} 0;
  }
`

const StyledProductPageHeaderMobileAndTablet = styled(ProductPageHeader)`
  padding: 0 ${theme.containerPadding};
  margin-bottom: ${theme.spacing.xl};

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledProductPageMediaSliderMobileAndTablet = styled(
  ProductPageMediaSlider,
)`
  margin-bottom: ${theme.spacing.md};

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.lg};
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const ContentGrid = styled.div`
  display: grid;

  @media ${minWidth('lg')} {
    grid-template-columns: auto ${theme.sizes.cartColumnWidth};
  }
`

const ContentColumn = styled.div`
  order: 1;
  max-width: 100vw;
  overflow-x: hidden;

  @media ${minWidth('lg')} {
    order: 0;
  }
`

const ProductContent = styled.div`
  padding: ${theme.spacing['2xl']} 0 ${theme.pagePadding};

  @media ${minWidth('lg')} {
    padding-top: ${theme.spacing['3xl']};
    border-right: ${theme.borders.base} ${theme.colors.border};
  }
`

const ContentContainer = styled.div`
  @media ${minWidth('lg')} {
    margin: 0 auto;
    max-width: ${theme.sizes.pdpContentWidth};
  }
`

const StyledProductPageMediaDesktop = styled(ProductPageMedia)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledAttributesTable = styled(AttributesTable)`
  margin-bottom: ${theme.spacing.lg};
`

const ShowAttributesModalButton = styled(ButtonUnstyled)`
  font-weight: ${theme.fontWeights.medium};
  text-align: left;
  text-decoration: underline;
`

const ProductPage = ({
  websiteData,
  productData,
  productParentData,
  productContentData,
  productParentContentData,
  categoryProductContentData,
  reviewContentData,
  disableEcommerce,
}: ProductPageStaticData & DefaultLayoutStaticData) => {
  const activeStoreView = useActiveStoreView()
  const [showReviewsModal, setShowReviewsModal] = useState<boolean>(false)
  const [cartAlert, setCartAlert] = useState<AlertProps | null>(null)
  const [showAttributesModal, setShowAttributesModal] = useState<boolean>(false)
  const [showCrossSellModal, setShowCrossSellModal] = useState<boolean>(false)
  const [isPushed, setIsPushed] = useState<boolean>(false)

  const [, addPreviouslyViewedProduct] = usePreviouslyViewedProducts()

  const handleCartStateChange = (cartAlert: AlertProps | null) => {
    setCartAlert(cartAlert)

    if (cartAlert?.type === 'success') {
      setShowCrossSellModal(true)
    }
  }

  // Craft product blocks
  const { beforeProductDataBlocks, afterProductDataBlocks } =
    getSplitCraftBlocks(productContentData ?? productParentContentData)

  // Store USPs
  const uspsProductPageSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftUspsProductPageGlobalSet',
  )

  const uspsGlobalStore =
    (uspsProductPageSet && 'usps' in uspsProductPageSet
      ? uspsProductPageSet.usps
      : []) ?? []

  const uspsProduct =
    (productContentData && 'usps' in productContentData
      ? productContentData.usps
      : []) ?? []
  const uspsStore = [...uspsGlobalStore, ...uspsProduct]

  // Collect and manage configurable data.
  const [selectedConfigurableValues, setSelectedConfigurableValues] = useState<
    string[] | undefined
  >()

  const product = productData?.productByUid
  const hasMainProduct =
    Boolean(productParentData) || product?.colorVariants?.length !== 0

  const { configurableOptions, isFetching } = useProductConfigurables(
    product,
    selectedConfigurableValues,
  )

  const handleConfigurableValueSelect = useCallback((values: string[]) => {
    setSelectedConfigurableValues(values)
  }, [])

  // Product attributes
  const highlightedAttributes =
    product?.highlightedAttributes?.filter(
      (attribute) => attribute?.attributeValue,
    ) ?? []

  const sortedHighlightedAttributes = sortArrayByProperty(
    highlightedAttributes ?? [],
    (attribute) =>
      attribute?.attributePosition || attribute?.attributePosition === 0
        ? attribute.attributePosition
        : null,
  )

  const attributes =
    product?.dynamicAttributes?.filter(
      (attribute) => attribute?.attributeValue,
    ) ?? []

  // Cross sell
  const crossSellProducts = product?.relatedProducts?.filter(stripMaybes)
  const crossSellProductCount = crossSellProducts?.length ?? 0
  const crossSellSectionTitle = t({ message: 'Often purchased together with' })

  // Media
  const mediaItems = product && getMediaItems(product)

  // Breadcrumbs
  const category = product?.categories?.[0]
  const breadcrumbParents = category
    ? [
        {
          name: category?.name,
          url: category?.urlKey,
        },
      ]
    : []

  const breadcrumbName = {
    name: product?.name,
    url: product?.canonicalUrl ?? undefined,
  }

  useEffect(() => {
    if (productData.productByUid?.sku) {
      addPreviouslyViewedProduct(productData.productByUid.sku)
    }
  }, [addPreviouslyViewedProduct, productData.productByUid?.sku])

  useEffect(() => {
    if (isPushed || !product) {
      return
    }

    setIsPushed(true)

    pushProductDetailView(
      [
        convertProductForTrackingEvent({
          product,
          quantity: 1,
          storeViewCode: activeStoreView.code,
        }),
      ],
      product.priceRange.minimumPrice.finalPrice.currency,
      product.priceRange.minimumPrice.finalPrice.value,
    )
  }, [activeStoreView.code, category?.name, isPushed, product])

  if (!productData || !product) {
    return null
  }

  return (
    <>
      <Meta
        metaTitle={product.metaTitle ?? getSeoPageTitle(product.name, 'brand')}
        metaDescription={
          product.metaDescription ? product.metaDescription : product.name
        }
      />

      {product.visibility === ProductVisibility.VISIBILITY_NOT_VISIBLE && (
        <MetaNoIndexNoFollow />
      )}

      <MetaCanonical />
      <SchemaProduct product={product} />

      <Container isLarge noPadding>
        <Alert type={cartAlert?.type}>{cartAlert?.message}</Alert>

        <BreadcrumbsWrapperMobileAndTablet>
          <StyledBreadcrumbs
            customName={breadcrumbName}
            customParents={breadcrumbParents}
          />
        </BreadcrumbsWrapperMobileAndTablet>

        {product.name && (
          <StyledProductPageHeaderMobileAndTablet
            title={product.name}
            reviewRating={product.ratingSummary ?? 0}
            reviewsCount={product.reviewCount ?? 0}
            showReviewsModal={showReviewsModal}
            setShowReviewsModal={setShowReviewsModal}
          />
        )}

        <StyledProductPageMediaSliderMobileAndTablet
          mediaItems={mediaItems ?? { galleryItems: [], ambianceItems: [] }}
          productLabels={product.productLabels?.filter(stripMaybes)}
        />

        <ContentGrid>
          <ContentColumn>
            <BreadcrumbsWrapperDesktop>
              <StyledBreadcrumbs
                customName={breadcrumbName}
                customParents={breadcrumbParents}
              />
            </BreadcrumbsWrapperDesktop>

            {mediaItems && (
              <StyledProductPageMediaDesktop
                mediaItems={mediaItems}
                productLabels={product.productLabels?.filter(stripMaybes)}
              />
            )}

            <ProductContent>
              <ContentContainer>
                {beforeProductDataBlocks &&
                  beforeProductDataBlocks?.length !== 0 && (
                    <CraftProductPageBlocks
                      blocks={beforeProductDataBlocks}
                      productData={productData}
                    />
                  )}

                {attributes.length !== 0 && (
                  <ProductPageSection
                    title={<Trans>Dimensions and details</Trans>}
                  >
                    {highlightedAttributes?.length !== 0 && (
                      <StyledAttributesTable
                        attributes={sortedHighlightedAttributes}
                      />
                    )}

                    <ShowAttributesModalButton
                      analyticsContext="product.page"
                      analyticsName="show.attributes.modal"
                      onClick={() => setShowAttributesModal(true)}
                    >
                      <Trans>Show all dimensions and details</Trans>
                    </ShowAttributesModalButton>
                  </ProductPageSection>
                )}

                {crossSellProducts && crossSellProductCount > 0 && (
                  <ProductPageSection title={crossSellSectionTitle}>
                    <ProductSliderWithTracking
                      disableEcommerce={disableEcommerce}
                      products={crossSellProducts}
                      slidesToShowMobile={
                        crossSellProductCount === 1 ? 1 : 1.25
                      }
                      slidesToShowTablet={getSlidesToShowTablet(
                        crossSellProductCount,
                      )}
                      slidesToShowDesktop={getSlidesToShowDesktop(
                        crossSellProductCount,
                      )}
                      trackingInfo={{
                        itemListId: 'related-products',
                        itemListName: crossSellSectionTitle,
                      }}
                    />
                  </ProductPageSection>
                )}

                {product && (
                  <ProductPageSection>
                    <Reviews
                      product={product}
                      showReviewsModal={showReviewsModal}
                      setShowReviewsModal={setShowReviewsModal}
                      reviewContentData={reviewContentData}
                      websiteData={websiteData}
                      disableEcommerce={disableEcommerce}
                      hasMainProduct={hasMainProduct}
                    />
                  </ProductPageSection>
                )}

                {afterProductDataBlocks &&
                  afterProductDataBlocks?.length !== 0 && (
                    <CraftProductPageBlocks
                      blocks={afterProductDataBlocks}
                      productData={productData}
                    />
                  )}

                {categoryProductContentData?.map(
                  (categoryProductContentDataBlocks) => {
                    const categoryProductDataBlocks =
                      categoryProductContentDataBlocks?.__typename ===
                      'CraftCategoryEntry'
                        ? categoryProductContentDataBlocks.categoryProductPageBlocks
                        : undefined

                    return categoryProductDataBlocks &&
                      categoryProductDataBlocks?.length !== 0 ? (
                      <CraftCategoryProductPageBlocks
                        blocks={categoryProductDataBlocks}
                      />
                    ) : null
                  },
                )}
              </ContentContainer>
            </ProductContent>
          </ContentColumn>

          <div>
            <ProductPageInfo
              websiteData={websiteData}
              isLoading={isFetching}
              product={product}
              productParentData={productParentData}
              handleCartStateChange={handleCartStateChange}
              usps={uspsStore}
              configurableOptions={configurableOptions}
              onConfigurableValueSelect={handleConfigurableValueSelect}
              showReviewsModal={showReviewsModal}
              setShowReviewsModal={setShowReviewsModal}
              disableEcommerce={disableEcommerce}
            />
          </div>
        </ContentGrid>
      </Container>

      <AttributesModal
        disableEcommerce={disableEcommerce}
        product={product}
        highlightedAttributes={highlightedAttributes}
        dynamicAttributes={attributes}
        productDownloads={product?.productDownloads}
        show={showAttributesModal}
        close={() => setShowAttributesModal(false)}
      />

      <CrossSellModal
        product={product}
        crossSellProducts={crossSellProducts}
        show={showCrossSellModal && !disableEcommerce}
        close={() => setShowCrossSellModal(false)}
      />
    </>
  )
}

export default ProductPage
