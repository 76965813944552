import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftProductPageBlocksFragment } from '../lib/craftFragments.generated'
import { ProductQuery } from '../pages/product/useProduct.generated'
import CraftCollapsibleContentBlocks from './CraftCollapsibleContentBlocks'
import CraftHighlightBannerLinkBlock from './CraftHighlightBannerLinkBlock'
import CraftProductFaqItemsBlock from './CraftProductFaqItemsBlock'
import CraftProductImageContentBannerBlock from './CraftProductImageContentBannerBlock'
import CraftProductUspsBlock from './CraftProductUspsBlock'

const renderBlock = (
  block: CraftProductPageBlocksFragment,
  index: number,
  productData?: ProductQuery,
) => {
  switch (block.__typename) {
    case 'CraftUspsEntry':
      return <CraftProductUspsBlock key={index} block={block} />
    case 'CraftImageContentBannerEntry':
      return <CraftProductImageContentBannerBlock key={index} block={block} />
    case 'CraftHighlightBannerLinkEntry':
      return (
        <CraftHighlightBannerLinkBlock
          key={index}
          block={block}
          productData={productData}
        />
      )
    case 'CraftFaqItemsEntry':
      return <CraftProductFaqItemsBlock key={index} block={block} />
    case 'CraftCollapsibleContentBlocksEntry':
      return (
        <CraftCollapsibleContentBlocks
          key={index}
          blocks={block.collapsibleContentBlockItems}
        />
      )
    case 'CraftBlockSeparationEntry':
      // This craft type is used for block separation logic only
      return null
    default:
      console.warn(`Craft block type "${block.__typename}" is not implemented`)

      return process.env.NODE_ENV === 'development' ? (
        <div key={index}>
          Craft block type "{block.__typename}" is not implemented
        </div>
      ) : null
  }
}

/**
 * Component to switch between Craft product page blocks
 * to render the corresponding craft blocks
 */
interface Props {
  blocks: Array<Maybe<CraftProductPageBlocksFragment>>
  productData?: ProductQuery
}

const CraftProductPageBlocks = ({ blocks, productData }: Props) => {
  if (!blocks || blocks.length === 0) {
    return null
  }

  return (
    <>
      {blocks.map((block, index) => {
        if (!block) {
          return null
        }

        return renderBlock(block, index, productData)
      })}
    </>
  )
}

export default CraftProductPageBlocks
