import React from 'react'

import { Container } from '@emico/ui'

import { CraftImageContentBannerListFragment } from '../lib/craftFragments.generated'
import CraftBlock from './CraftBlock'
import CraftProductImageContentBannerBlock from './CraftProductImageContentBannerBlock'

interface Props {
  block: CraftImageContentBannerListFragment
  hasContainerPadding?: boolean
}

const CraftImageContentBannerListBlock = ({
  block,
  hasContainerPadding = true,
}: Props) => (
  <CraftBlock>
    <Container noPadding={!hasContainerPadding}>
      {block.imageContentBannerListItems.map((block, index) => {
        if (!block) {
          return null
        }

        return (
          <CraftProductImageContentBannerBlock
            key={index}
            block={block}
            isNestedBlock
          />
        )
      })}
    </Container>
  </CraftBlock>
)

export default CraftImageContentBannerListBlock
