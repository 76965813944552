import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { CheckmarkRoundIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'

import CrossRoundIcon from '../icons/CrossRoundIcon'
import theme from '../theme'
import HtmlContent from './HtmlContent'

const List = styled.dl`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0;

  @media ${minWidth('md')} {
    grid-template-columns: 40% 60%;
  }
`

const attributeStyling = css`
  padding: ${theme.spacing.sm};
  font-size: ${theme.fontSizes.sm};

  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundLight};
  }
`

const Label = styled('dt', {
  shouldForwardProp: (prop) => !['isBold'].includes(prop.toString()),
})<{ isBold: boolean }>`
  ${attributeStyling};
  font-weight: ${({ isBold }) => isBold && theme.fontWeights.bold};
`

const Value = styled.dd`
  ${attributeStyling};
  margin: 0;
`

const StyledCheckmarkRoundIcon = styled(CheckmarkRoundIcon)`
  color: ${theme.colors.success};
  font-size: 15px;
`

const StyledCrossRoundIcon = styled(CrossRoundIcon)`
  color: ${theme.colors.grayBrown};
  font-size: 15px;
`

const getValue = (value: string) => {
  switch (value) {
    case 'true':
      return <StyledCheckmarkRoundIcon />
    case 'false':
      return <StyledCrossRoundIcon />
    default:
      return value
  }
}

interface Props {
  tableItems: Array<{
    label: string
    value: string
  }>
  hasBoldLabels?: boolean
  hasRichTextValues?: boolean
}

const Table = ({
  tableItems,
  hasBoldLabels = false,
  hasRichTextValues = false,
  ...other
}: Props) => (
  <List {...other}>
    {tableItems.map(({ label, value }) => (
      <Fragment key={label}>
        <Label isBold={hasBoldLabels}>{label}</Label>

        <Value>
          {hasRichTextValues ? <HtmlContent html={value} /> : getValue(value)}
        </Value>
      </Fragment>
    ))}
  </List>
)

export default Table
