import { Maybe } from '@emico-hooks/graphql-schema-types'
import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'

import {
  CraftCollapsibleContentBlockItemListFragment,
  CraftCollapsibleContentBlockItemsFragment,
} from '../lib/craftFragments.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import CraftBlock from './CraftBlock'
import CraftImageContentBannerListBlock from './CraftImageContentBannerListBlock'
import CraftInformationItemBlockContent from './CraftInformationItemBlockContent'
import CraftSimpleContentBlock from './CraftSimpleContentBlock'
import CraftTableBlock from './CraftTableBlock'

const StyledCraftBlock = styled(CraftBlock)`
  padding-left: ${theme.containerPadding};
  padding-right: ${theme.containerPadding};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  &:first-of-type {
    border-top: ${theme.borders.default};
  }

  ${AccordionHeader} {
    padding: ${theme.spacing.sm} 0;
  }
`

const Label = styled.span`
  font-size: ${theme.fontSizes.lg};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.lg};
`

const renderBlock = (
  block: CraftCollapsibleContentBlockItemListFragment,
  index: number,
) => {
  switch (block.__typename) {
    case 'CraftSimpleContentEntry':
      return <CraftSimpleContentBlock key={index} block={block} isNestedBlock />
    case 'CraftInformationItemsBlockEntry':
      return <CraftInformationItemBlockContent key={index} block={block} />
    case 'CraftImageContentBannerListEntry':
      return (
        <CraftImageContentBannerListBlock
          key={index}
          block={block}
          hasContainerPadding={false}
        />
      )
    case 'CraftTableEntry':
      return <CraftTableBlock key={index} block={block} isNestedBlock />

    default:
      console.warn(
        `Craft collapsible content block type "${block.__typename}" is not implemented`,
      )

      return process.env.NODE_ENV === 'development' ? (
        <div key={index}>
          Craft collapsible content block type "{block.__typename}" is not
          implemented
        </div>
      ) : null
  }
}

/**
 * Component to switch between Craft collapsible content blocks
 * to render the corresponding craft blocks
 */
interface Props {
  blocks: Array<Maybe<CraftCollapsibleContentBlockItemsFragment>>
}

const CraftCollapsibleContentBlocks = ({ blocks }: Props) => {
  if (!blocks || blocks.length === 0) {
    return null
  }

  return (
    <StyledCraftBlock>
      {blocks.map((block, index) => {
        if (!block) {
          return null
        }

        return (
          <StyledAccordion
            key={`${block.title}-${index}`}
            label={<Label>{block.title}</Label>}
            labelElement="h2"
          >
            <ItemContent key={index}>
              {block.collapsibleContentBlockItemList.map((collapsibleBlock) => {
                if (!collapsibleBlock) {
                  return null
                }

                return renderBlock(collapsibleBlock, index)
              })}
            </ItemContent>
          </StyledAccordion>
        )
      })}
    </StyledCraftBlock>
  )
}

export default CraftCollapsibleContentBlocks
