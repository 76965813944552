import { ButtonUnstyled } from '@emico-react/buttons'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'

import { maxWidth, minWidth } from '@emico/styles'

import ChevronDownIcon from '../icons/ChevronDownIcon'
import getDate from '../lib/getDate'
import { AmastyReviewFragment } from '../lib/useAdvReview.generated'
import theme from '../theme'
import ImageRow from './ImageRow'
import StarRatingWithTotals from './StarRatingWithTotals'

const ReviewBlock = styled.div`
  padding: ${theme.spacing.lg} 0;

  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const StyledStarRatingWithTotals = styled(StarRatingWithTotals)`
  margin-bottom: ${theme.spacing.sm};
`

const Title = styled.span`
  display: block;
  margin-bottom: ${theme.spacing.md};
  font-weight: ${theme.fontWeights.bold};
`

const Text = styled('p', {
  shouldForwardProp: (prop) =>
    !['showAll', 'readMoreLines'].includes(prop.toString()),
})<{ showAll: boolean; readMoreLines?: number }>`
  margin-bottom: 0;
  white-space: pre-line;

  ${({ showAll, readMoreLines = 1 }) =>
    !showAll && theme.stylingSnippet.lineClamp(readMoreLines)}
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  margin-top: ${theme.spacing.sm};
  text-decoration: underline;
`

const StyledChevronDownIcon = styled(ChevronDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isToggled',
})<{ isToggled?: boolean }>`
  margin-left: ${theme.spacing.xs};
  font-size: 12px;
  transform: ${({ isToggled }) => (isToggled ? 'rotate(-180deg)' : 0)};
`

const StyledImageRow = styled(ImageRow)`
  margin-top: ${theme.spacing.lg};
`

const StyledImageRowMobile = styled(StyledImageRow)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledImageRowTablet = styled(StyledImageRow)`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledImageRowDesktop = styled(StyledImageRow)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const Details = styled.div`
  margin-top: ${theme.spacing.md};
  color: ${theme.colors.grayBrown};
`

const DetailsItem = styled.span`
  &:not(:first-of-type) {
    &::before {
      content: '\0020 \007C \0020';
    }
  }
`

interface Props {
  review: AmastyReviewFragment
  readMoreLines?: number
  hasMainProduct?: boolean
}

const Review = ({ review, readMoreLines = 3, hasMainProduct }: Props) => {
  const [showFullText, setShowFullText] = useState<boolean>(false)
  const [showCollapseButtons, setShowCollapseButtons] = useState<boolean>(false)

  const images =
    review.images?.map((image) => image?.fullPath).filter(stripMaybes) ?? []
  const reviewRating = review.ratingVotes?.[0]?.value
  const detailsRef = useRef<HTMLParagraphElement | null>(null)
  const isVerifiedBuyer = Boolean(review.verifiedBuyer)
  const isSponsoredReview = Boolean(review.isSponsored)

  useEffect(() => {
    const show =
      (detailsRef.current?.offsetHeight ?? 0) <
        (detailsRef.current?.scrollHeight ?? 0) ||
      (detailsRef.current?.offsetWidth ?? 0) <
        (detailsRef.current?.scrollWidth ?? 0)

    setShowCollapseButtons(show)
  }, [])

  return (
    <ReviewBlock>
      {reviewRating && (
        <StyledStarRatingWithTotals value={reviewRating} showStarValue />
      )}

      <Title>{review.title}</Title>

      <Collapse isOpened>
        <Text
          showAll={showFullText}
          readMoreLines={readMoreLines}
          ref={detailsRef}
        >
          {review.detail}
        </Text>
      </Collapse>

      {showCollapseButtons &&
        (showFullText ? (
          <StyledButtonUnstyled
            analyticsContext="category"
            analyticsName="introduction-read-less"
            onPress={() => setShowFullText(false)}
          >
            <Trans>Read less</Trans>

            <StyledChevronDownIcon isToggled={showFullText} />
          </StyledButtonUnstyled>
        ) : (
          <StyledButtonUnstyled
            analyticsContext="category"
            analyticsName="introduction-read-more"
            onPress={() => setShowFullText(true)}
          >
            <Trans>Read more</Trans>

            <StyledChevronDownIcon isToggled={showFullText} />
          </StyledButtonUnstyled>
        ))}

      {images?.length !== 0 && (
        <>
          <StyledImageRowMobile imageUrls={images} visibleImageCount={4} />
          <StyledImageRowTablet imageUrls={images} visibleImageCount={6} />
          <StyledImageRowDesktop imageUrls={images} />
        </>
      )}

      <Details>
        <DetailsItem>{review.nickname}</DetailsItem>

        {review.createdAt && (
          <DetailsItem>{getDate(review.createdAt)}</DetailsItem>
        )}

        {isSponsoredReview && (
          <DetailsItem>
            <Trans>Sponsored review</Trans>
          </DetailsItem>
        )}

        {isVerifiedBuyer && (
          <DetailsItem>
            <Trans>Verified purchase</Trans>
          </DetailsItem>
        )}

        {hasMainProduct && (
          <DetailsItem>
            <Trans>Written for: {review.product.name}</Trans>
          </DetailsItem>
        )}
      </Details>
    </ReviewBlock>
  )
}

export default Review
