import Head from 'next/head'
import React from 'react'

/**
 * Component to add meta noindex nofollow data in <head>
 */
const MetaNoIndexNoFollow = () => (
  <Head>
    <meta name="robots" content="noindex, nofollow" />
  </Head>
)

export default MetaNoIndexNoFollow
