import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { MutableRefObject, ReactNode, useState } from 'react'

import { ProductStockStatus } from '@emico/graphql-schema-types'
import { CrossIcon } from '@emico/icons'
import { Modal } from '@emico/modal'
import ModalBackdrop from '@emico/modal-backdrop'
import { ModalSegueRight } from '@emico/modal-segue'
import { minWidth, maxWidth } from '@emico/styles'
import { ButtonUnstyled, H1, H2 } from '@emico/ui'

import { AlertProps } from '../lib/customTypes'
import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import Alert from './Alert'
import ConfigurableAddToCartForm from './ConfigurableAddToCartForm'
import RegularFinalPrice from './RegularFinalPrice'

const Sidebar = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: ${theme.colors.background};

  @media ${minWidth('lg')} {
    max-width: 800px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background-color: ${theme.colors.backgroundDark};
  min-height: ${theme.sizes.uspBarHeight};
`

const Title = styled(H1)`
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.textLight};
  text-transform: none;
`

const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-bottom: ${theme.borders.default};
`

const ProductColumn = styled.div`
  display: flex;
  align-items: center;
`

const StyledConfigurableAddToCartFormMobileAndTablet = styled(
  ConfigurableAddToCartForm,
)`
  display: flex;
  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledConfigurableAddToCartFormDesktop = styled(
  ConfigurableAddToCartForm,
)`
  display: flex;
  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledImage = styled(Image)`
  margin-right: ${theme.spacing.sm};
`

const ProductPrependText = styled.p`
  margin-bottom: 0;
`

const ProductName = styled(H2)`
  font-size: ${theme.fontSizes.md};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.lg};
  }
`

const Content = styled.div`
  padding: ${theme.spacing.md};
  overscroll-behavior: contain;
  overflow: auto;
`

const StyledCrossIcon = styled(CrossIcon)`
  color: ${theme.colors.textLight};
`

interface ProductHeaderWithCartButtonProps {
  disableEcommerce?: boolean
  product: ProductFragment
  productPrependText?: string
  showProductPrice?: boolean
  showCartButton?: boolean
  setCartAlert: (alert: AlertProps | null) => void
}

const ProductHeaderWithCartButton = ({
  disableEcommerce,
  product,
  productPrependText,
  showProductPrice,
  showCartButton,
  setCartAlert,
}: ProductHeaderWithCartButtonProps) => {
  const isConfigurableProduct = product.__typename === 'ConfigurableProduct'
  const isOutOfStock = product.stockStatus === ProductStockStatus.OUT_OF_STOCK

  const handleCartStateChange = (cartAlert: AlertProps | null) => {
    setCartAlert(cartAlert)
  }

  return (
    <ProductHeader>
      <ProductColumn>
        {product.smallImage?.url && (
          <StyledImage
            url={product.smallImage?.url}
            alt={product.smallImage.label ?? ''}
            lazy={false}
            height={40}
          />
        )}

        <div>
          {productPrependText && (
            <ProductPrependText>{productPrependText}</ProductPrependText>
          )}

          <ProductName>{product.name}</ProductName>

          {showProductPrice && (
            <RegularFinalPrice
              regularPrice={product.priceRange.minimumPrice.regularPrice}
              finalPrice={product.priceRange.minimumPrice.finalPrice}
              percentOff={product.priceRange.minimumPrice.discount?.percentOff}
            />
          )}
        </div>
      </ProductColumn>

      {showCartButton && !isOutOfStock && (
        <>
          <ProductColumn>
            <StyledConfigurableAddToCartFormMobileAndTablet
              isOutOfStock={isOutOfStock}
              product={product}
              handleCartStateChange={handleCartStateChange}
              hideAddToCartButton={isConfigurableProduct || disableEcommerce}
              showButtonsOnly
              isIconButton
            />
            <StyledConfigurableAddToCartFormDesktop
              isOutOfStock={isOutOfStock}
              product={product}
              handleCartStateChange={handleCartStateChange}
              hideAddToCartButton={isConfigurableProduct || disableEcommerce}
              showButtonsOnly
            />
          </ProductColumn>
        </>
      )}
    </ProductHeader>
  )
}

interface Props {
  disableEcommerce?: boolean
  show: boolean
  close: () => void
  children: ReactNode
  title: ReactNode
  product?: ProductFragment
  productPrependText?: string
  showProductPrice?: boolean
  showCartButton?: boolean
  hasFocusTrap?: boolean
  scrollRef?: MutableRefObject<HTMLDivElement | null>
}

const SideModal = ({
  disableEcommerce,
  show,
  close,
  children,
  title,
  product,
  productPrependText,
  showProductPrice = true,
  showCartButton = false,
  hasFocusTrap = true,
  scrollRef,
}: Props) => {
  const [cartAlert, setCartAlert] = useState<AlertProps | null>(null)

  return (
    <>
      <ModalBackdrop show={show} />

      <ModalSegueRight show={show}>
        <Modal close={close} show={show} hasFocusTrap={hasFocusTrap}>
          <Sidebar>
            <header>
              <TitleWrapper>
                <Title>{title}</Title>

                <ButtonUnstyled
                  analyticsContext="sideModal"
                  analyticsName="close"
                  onClick={close}
                >
                  <StyledCrossIcon />
                </ButtonUnstyled>
              </TitleWrapper>

              {product && (
                <>
                  <ProductHeaderWithCartButton
                    disableEcommerce={disableEcommerce}
                    product={product}
                    productPrependText={productPrependText}
                    showProductPrice={showProductPrice}
                    showCartButton={showCartButton}
                    setCartAlert={setCartAlert}
                  />

                  <Alert type={cartAlert?.type}>{cartAlert?.message}</Alert>
                </>
              )}
            </header>

            <Content ref={scrollRef}>{children}</Content>
          </Sidebar>
        </Modal>
      </ModalSegueRight>
    </>
  )
}

export default SideModal
